import {locationsConstants} from "../constants";

export function  locations(state ={}, action){
    switch (action.type) {
        case locationsConstants.LOCATIONS_GETALL_REQUEST:
            return{
                loading: true
            };
        case locationsConstants.LOCATIONS_GETALL_SUCCESS:
            return {
                loading: false,
                items: action.locations.results
            };
        case locationsConstants.LOCATIONS_GETALL_FAILURE:
            return {
                loading: false,
                error: action.error.toString()
            };
        case locationsConstants.LOCATIONS_GETITEM_REQUEST:
            return {
                item_loading: true
            };
        case locationsConstants.LOCATIONS_GETITEM_SUCCESS:
            return {
                item_loading: false,
                item:action.location
            };
        case locationsConstants.LOCATIONS_GETITEM_FAILURE:
            return {
                item_loading: false,
                item:action.error.toString()
            }
        case locationsConstants.LOCATIONS_ADD_ITEM_REQUEST:
            return{
                ...state,
                creating: true
            }
        case locationsConstants.LOCATIONS_ADD_ITEM_SUCCESS:
            return{
                ...state,
                creating: false,
                items:[...state.items, action.location]
            }
        case locationsConstants.LOCATIONS_ADD_ITEM_FAILURE:
            return {
                ...state,
                creating: false,
                err: action.error.toString()
            }
        case locationsConstants.LOCATIONS_REMOVE_ITEM_REQUEST:
            let _items = state.items.map(
                item => item.id === action.location.locationId ? 
                {...item, deleting: true} :
                item
            )
            return {
                ...state,
                items: _items
            }
        case locationsConstants.LOCATIONS_REMOVE_ITEM_SUCCESS:
            _items = state.items.filter(item => item.id !== action.location.locationId)
            return{
                ...state,
                items:_items
            }
        case locationsConstants.LOCATIONS_REMOVE_ITEM_FAILURE:
            _items = state.items.map(
                item => {
                    if (item.id === action.location.locationId){
                        const {deleting, ...itemCopy} = item;
                        return{...itemCopy, removeError: action.error}
                    }
                    return item;
                }
            )
            return{
                ...state,
                items: _items
            }
        case locationsConstants.LOCATIONS_UPDATE_ITEM_REQUEST:
            _items = state.items.map(
                item => item.id === action.location.locationId ? 
                {...item, updating: true} :
                item
            )
            return {
                ...state,
                items: _items
            }
        case locationsConstants.LOCATIONS_UPDATE_ITEM_SUCCESS:
            _items = state.items.map(
                item => {
                    if (item.id === action.location.id){
                        return action.location
                    }
                    return item;
                }
            )
            return{
                ...state,
                items:_items
            }
        case locationsConstants.LOCATIONS_UPDATE_ITEM_FAILURE:
            _items = state.items.map(
                item => {
                    if (item.id === action.location.locationId){
                        const {updating, ...itemCopy} = item;
                        return{...itemCopy, updateError: action.error}
                    }
                    return item;
                }
            )
            return{
                ...state,
                items: _items
            }
        case locationsConstants.LOCATIONS_ACTIVATE_ITEM_REQUEST:
            _items = state.items.map(
                item => item.id === action.location.locationId ? 
                {...item, activating: true} :
                item
            )
            return {
                ...state,
                items: _items
            }
        case locationsConstants.LOCATIONS_ACTIVATE_ITEM_SUCCESS:
            _items = state.items.map(
                item => {
                    if (item.id === action.location.id){
                        return action.location
                    }
                    return item;
                }
            )
            return{
                ...state,
                items:_items
            }
        case locationsConstants.LOCATIONS_ACTIVATE_ITEM_FAILURE:
            _items = state.items.map(
                item => {
                    if (item.id === action.location.locationId){
                        const {activating, ...itemCopy} = item;
                        return{...itemCopy, updateError: action.error}
                    }
                    return item;
                }
            )
            return{
                ...state,
                items: _items
            }
        default:
            return state;
    }

}