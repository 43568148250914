export const locationsConstants = {
        LOCATIONS_GETALL_REQUEST: "LOCATIONS_GETALL_REQUEST",
        LOCATIONS_GETALL_SUCCESS: "LOCATIONS_GETALL_SUCCESS",
        LOCATIONS_GETALL_FAILURE: "LOCATIONS_GETALL_FAILURE",

        LOCATIONS_GETITEM_REQUEST: "LOCATIONS_GETITEM_REQUEST",
        LOCATIONS_GETITEM_SUCCESS: "LOCATIONS_GETITEM_SUCCESS",
        LOCATIONS_GETITEM_FAILURE: "LOCATIONS_GETITEM_FAILURE",

        LOCATIONS_ADD_ITEM_REQUEST: "LOCATIONS_ADD_ITEM_REQUEST",
        LOCATIONS_ADD_ITEM_SUCCESS: "LOCATIONS_ADD_ITEM_SUCCESS",
        LOCATIONS_ADD_ITEM_FAILURE: "LOCATIONS_ADD_ITEM_FAILURE",

        LOCATIONS_UPDATE_ITEM_REQUEST: "LOCATIONS_UPDATE_ITEM_REQUEST",
        LOCATIONS_UPDATE_ITEM_SUCCESS: "LOCATIONS_UPDATE_ITEM_SUCCESS",
        LOCATIONS_UPDATE_ITEM_FAILURE: "LOCATIONS_UPDATE_ITEM_FAILURE",

        LOCATIONS_REMOVE_ITEM_REQUEST: "LOCATIONS_REMOVE_ITEM_REQUEST",
        LOCATIONS_REMOVE_ITEM_SUCCESS: "LOCATIONS_REMOVE_ITEM_SUCCESS",
        LOCATIONS_REMOVE_ITEM_FAILURE: "LOCATIONS_REMOVE_ITEM_FAILURE",

        LOCATIONS_ACTIVATE_ITEM_REQUEST: "LOCATIONS_ACTIVATE_ITEM_REQUEST",
        LOCATIONS_ACTIVATE_ITEM_SUCCESS: "LOCATIONS_ACTIVATE_ITEM_SUCCESS",
        LOCATIONS_ACTIVATE_ITEM_FAILURE: "LOCATIONS_ACTIVATE_ITEM_FAILURE"
}