export const userConstants = {
    
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    USERS_GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    USERS_GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    USERS_GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    USER_DELETE_REQUEST: 'USER_DELETE_REQUEST',
    USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
    USER_DELETE_FAILURE: 'USER_DELETE_FAILURE',
    
    USER_EDIT_REQUEST: 'USER_EDIT_REQUEST',
    USER_EDIT_SUCCESS: 'USER_EDIT_SUCCESS',
    USER_EDIT_FAILURE: 'USER_EDIT_FAILURE',

    USER_ADD_REQUEST: 'USER_ADD_REQUEST',
    USER_ADD_SUCCESS: 'USER_ADD_SUCCESS',
    USER_ADD_FAILURE: 'USER_ADD_FAILURE',

    USER_GET_REQUEST: 'USER_GET_REQUEST',
    USER_GET_SUCCESS: 'USER_GET_SUCCESS',
    USER_GET_FAILURE: 'USER_GET_FAILURE',

    ME_UPDATE_REQUEST: 'ME_UPDATE_REQUEST',
    ME_UPDATE_SUCCESS: 'ME_UPDATE_SUCCESS',
    ME_UPDATE_FAILURE: 'ME_UPDATE_FAILURE',

    LOGOUT: 'USERS_LOGOUT',
}