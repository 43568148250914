import {userConstants} from '../constants';

let userData = localStorage.getItem('user');
let user = (userData === "undefined" || !userData) ? "" : JSON.parse(userData);
const initialState = user ? {loggedIn: true, user} : {};

export function auth(state = initialState, action){
    switch (action.type){
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn : true,
                user: action.user
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };
        case userConstants.LOGIN_FAILURE:
            return {
                logFailed: true,
                error: action.error.toString()
            };
        case userConstants.LOGOUT:
            return {};
        default:
            return state
    }
}