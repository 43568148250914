import React from 'react';

//const SignUp1 = React.lazy(() => import('./Container/Authentication/SignUp/SignUp1'));
//const Signin = React.lazy(() => import('./Container/Authentication/SignIn/SignIn'));
const CustomSignUp = React.lazy(() => import('./Container/Authentication/SignIn/CustomSignUp'));
const CustomSignUp_R = React.lazy(() => import('./Container/Authentication/SignIn/CustomSignUp_R'));
// const CustomSignUpDefault = React.lazy( () => import('./Container/Authentication/SignIn/CustomSignUpDefault') )
const VisitInfo = React.lazy( () => import('./Container/VisitInfo/index') );
const VisitInfoBypass = React.lazy( () => import('./Container/VisitInfoBypass/index') );
const WaitlistDisplay = React.lazy( () => import('./Container/WaitlistDisplay/index') );
const Leaderboard = React.lazy( () => import('./Container/Leaderboard/index') );
const UserListView = React.lazy( () => import('./Container/UserListView/index') );

const route = [
    { path: '/signup', exact: true, name: 'CustomSignUp', component: CustomSignUp },
    { path: '/R/signup', exact: true, name: 'CustomSignUp1', component: CustomSignUp_R },
    { path: '/I/:locationName/:locationId/:visitId', exact: true, name: 'VisitInfo', component: VisitInfo },
    { path: '/I/:locationName/:locationId', exact: true, name: 'VisitInfoBypass', component: VisitInfoBypass },
    { path: '/wait-list/:locationName/:locationId', exact: true, name: 'WaitlistDisplay', component: WaitlistDisplay },
    { path: '/leaderboard', exact: true, name: 'Leaderboard', component: Leaderboard },
    { path: '/userlist', exact: true, name: 'UserListView', component: UserListView }
];

export default route;