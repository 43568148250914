import common_constant from "../common_constant";
import {dryvwaitlistConstants} from "../constants";

export function  dryvwaitlist(state ={}, action){

    let _w_items = [];
    let _s_items = [];
    let _c_items = [];

    switch (action.type) {
        case dryvwaitlistConstants.DRYVWAITLIST_GETALL_REQUEST:
            return{
                loading: true
            };
        case dryvwaitlistConstants.DRYVWAITLIST_GETALL_SUCCESS:
            return {
                items: action.dryvwaitlist.results,
                loading: false
            };
        case dryvwaitlistConstants.DRYVWAITLIST_GETALL_FAILURE:
            return {
                error: action.error.toString(),
                loading: false
            };
        case dryvwaitlistConstants.DRYVWAITLIST_GETENTIRE_REQUEST:
            return{
                ...state,
                loading: true
            };
        case dryvwaitlistConstants.DRYVWAITLIST_GETENTIRE_SUCCESS:
            return {
                ...state,
                items: action.dryvwaitlist.results,
                loading: false
            };
        case dryvwaitlistConstants.DRYVWAITLIST_GETENTIRE_FAILURE:
            return {
                ...state,
                error: action.error.toString(),
                loading: false
            };

        case dryvwaitlistConstants.WAITING_DRYVWAITLIST_GETENTIRE_REQUEST:
            return{
                ...state,
                w_loading: true
            };
        case dryvwaitlistConstants.WAITING_DRYVWAITLIST_GETENTIRE_SUCCESS:

            /*let _w_items1 = state.w_items ? state.w_items : [];
            for (let i = 0; i < action.visits.results.length; i ++){
                let exist = false;
                _w_items1 = _w_items1.map((item, index) => {
                    if (item.id === action.visits.results[i].id){
                        exist = true;
                        return action.visits.results[i];
                    }

                    return item;
                })

                if (!exist) _w_items1.push(action.visits.results[i]);
            }*/

            return {
                ...state,
                w_items: action.dryvwaitlist.results, //_w_items1,
                w_loading: false
            };
        case dryvwaitlistConstants.WAITING_DRYVWAITLIST_GETENTIRE_FAILURE:
            return {
                ...state,
                w_error: action.error.toString(),
                w_loading: false
            };

        case dryvwaitlistConstants.SERVING_DRYVWAITLIST_GETENTIRE_REQUEST:
            return{
                ...state,
                s_loading: true
            };
        case dryvwaitlistConstants.SERVING_DRYVWAITLIST_GETENTIRE_SUCCESS:
            return {
                ...state,
                s_items: action.dryvwaitlist.results,
                s_loading: false
            };
        case dryvwaitlistConstants.SERVING_DRYVWAITLIST_GETENTIRE_FAILURE:
            return {
                ...state,
                s_error: action.error.toString(),
                s_loading: false
            };

        case dryvwaitlistConstants.COMPLETE_DRYVWAITLIST_GETENTIRE_REQUEST:
            return{
                ...state,
                c_loading: true
            };
        case dryvwaitlistConstants.COMPLETE_DRYVWAITLIST_GETENTIRE_SUCCESS:

            // let _c_items1 = state.c_items ? state.c_items : [];
            // if (action.visits && action.visits.results){
            //     for (let i = 0; i < action.visits.results.length; i ++){
            //         if (_c_items1.filter(item => item.id === action.visits.results[i].id).length === 0){
            //             _c_items1.push(action.visits.results[i]);
            //         }
            //     }
            // }

            // let _c_startAfter = state.c_startAfter ? state.c_startAfter : '';
            // if (action.visits && action.visits.endAt) _c_startAfter = action.visits.endAt;

            return {
                ...state,
                c_items:  action.dryvwaitlist.results,// _c_items1,
                //c_startAfter: _c_startAfter,
                c_loading: false
            };
        case dryvwaitlistConstants.COMPLETE_DRYVWAITLIST_GETENTIRE_FAILURE:
            return {
                ...state,
                c_error: action.error.toString(),
                c_loading: false
            };

        case dryvwaitlistConstants.DRYVWAITLIST_GETENTIRE_RELOAD_REQUEST:
            return{
                ...state,
                loading: false,
                pos_reloading: true
            };
        case dryvwaitlistConstants.DRYVWAITLIST_GETENTIRE_RELOAD_SUCCESS:
            return {
                ...state,
                w_items: action.dryvwaitlist.results,
                pos_reloading: false,
                loading: false
            };
        case dryvwaitlistConstants.DRYVWAITLIST_GETENTIRE_RELOAD_FAILURE:
            return {
                ...state,
                error: action.error.toString(),
                pos_reloading: false,
                loading: false
            };
        case dryvwaitlistConstants.DRYVWAITLIST_GETITEM_REQUEST:
            return {
                loading: true
            };
        case dryvwaitlistConstants.DRYVWAITLIST_GETITEM_SUCCESS:
            return {
                loading: false,
                item:action.dryvwaitlist
            };
        case dryvwaitlistConstants.DRYVWAITLIST_GETITEM_FAILURE:
            return {
                loading: false,
                item:action.error.toString()
            }
        case dryvwaitlistConstants.DRYVWAITLIST_UPDATEITEM_REQUEST:
            return {
                loading: true
            };
        case dryvwaitlistConstants.DRYVWAITLIST_UPDATEITEM_SUCCESS:
            return {
                loading: false,
                item:action.dryvwaitlist
            };
        case dryvwaitlistConstants.DRYVWAITLIST_UPDATEITEM_FAILURE:
            return {
                loading: false,
                item:action.error.toString()
            }
        case dryvwaitlistConstants.DRYVWAITLIST_REMOVEITEM_REQUEST:

            let _items = state.w_items.map(
                item => item.id === action.dryvwaitlist.deleted_id ? 
                {...item, deleting: true} :
                item
            )
            return {
                ...state,
                w_items: _items
            }

        case dryvwaitlistConstants.DRYVWAITLIST_REMOVEITEM_SUCCESS:

            let updatedItems = state.w_items.filter(item => item.id !== action.dryvwaitlist.deleted_id)
            return{
                ...state,
                w_items: updatedItems,
            }
        case dryvwaitlistConstants.DRYVWAITLIST_REMOVEITEM_FAILURE:

            _items = state.w_items.map(
                item => {
                    if (item.id === action.dryvwaitlist.deleted_id){
                        const {deleting, ...itemCopy} = item;
                        return{...itemCopy, removeError: action.error}
                    }
                    return item;
                }
            )
            return{
                ...state,
                w_items: _items
            }
        case dryvwaitlistConstants.DRYVWAITLIST_ITEMSTATUS_UPDATE_REQUEST:
            _w_items = state.w_items.map(
                item => item.id === action.dryvwaitlist.dryvwaitlistId ? 
                (action.dryvwaitlist.resendParam ?
                {...item, item_alerting: true} :
                {...item, item_updating: true}) :
                item
            )

            _s_items = state.s_items.map(
                item => item.id === action.dryvwaitlist.dryvwaitlistId ? 
                (action.dryvwaitlist.resendParam ?
                {...item, item_alerting: true} :
                {...item, item_updating: true}) :
                item
            )

            _c_items = state.c_items.map(
                item => item.id === action.dryvwaitlist.dryvwaitlistId ? 
                (action.dryvwaitlist.resendParam ?
                {...item, item_alerting: true} :
                {...item, item_updating: true}) :
                item
            )
            return {
                ...state,
                w_items: _w_items,
                s_items: _s_items,
                c_items: _c_items
            }
        case dryvwaitlistConstants.DRYVWAITLIST_ITEMSTATUS_UPDATE_SUCCESS:
            _w_items = state.w_items.filter(item => !(item.id === action.dryvwaitlist.id && action.dryvwaitlist.waitlistState !== common_constant.WAITWHILE_STATE_WAIT ));
            _s_items = state.s_items.filter(item => !(item.id === action.dryvwaitlist.id && action.dryvwaitlist.waitlistState !== common_constant.WAITWHILE_STATE_SERVING ));
            _c_items = state.c_items.filter(item => !(item.id === action.dryvwaitlist.id && action.dryvwaitlist.waitlistState !== common_constant.WAITWHILE_STATE_COMPLETE ));

            if (action.dryvwaitlist.waitlistState === common_constant.WAITWHILE_STATE_WAIT){
                let exist = false;
                _w_items = _w_items.map((item, index) => {
                    if (item.id === action.dryvwaitlist.id){
                        exist = true;
                        return action.dryvwaitlist;
                    }
                    return item;
                })
                if (!exist) _w_items.push(action.dryvwaitlist);
            }else if (action.dryvwaitlist.waitlistState === common_constant.WAITWHILE_STATE_SERVING){
                _s_items.push(action.dryvwaitlist);
            }else if (action.dryvwaitlist.waitlistState === common_constant.WAITWHILE_STATE_COMPLETE){
                if (_c_items.filter(item => item.id === action.dryvwaitlist.id).length === 0){
                    _c_items.push(action.dryvwaitlist);
                }
            }

            return{
                ...state,
                w_items: _w_items,
                s_items: _s_items,
                c_items: _c_items
            }
        case dryvwaitlistConstants.DRYVWAITLIST_ITEMSTATUS_UPDATE_FAILURE:
            _w_items = state.w_items.map(
                item => {
                    if (item.id === action.dryvwaitlist.dryvwaitlistId){
                            const {item_alerting, item_updating, ...itemCopy} = item;
                            return{...itemCopy, updateError: action.error}
                    }
                    return item;
                }
            )
            _s_items = state.s_items.map(
                item => {
                    if (item.id === action.dryvwaitlist.dryvwaitlistId){
                            const {item_alerting, item_updating, ...itemCopy} = item;
                            return{...itemCopy, updateError: action.error}
                    }
                    return item;
                }
            )
            _c_items = state.c_items.map(
                item => {
                    if (item.id === action.dryvwaitlist.dryvwaitlistId){
                            const {item_alerting, item_updating, ...itemCopy} = item;
                            return{...itemCopy, updateError: action.error}
                    }
                    return item;
                }
            )
            return{
                ...state,
                w_items: _w_items,
                s_items: _s_items,
                c_items: _c_items
            }
        case dryvwaitlistConstants.DRYVWAITLIST_ITEM_POS_UPDATE_REQUEST:

            const posForward = action.dryvwaitlist.posForward;

            _items = state.w_items.map(
                item => {
                if (item.id === action.dryvwaitlist.dryvwaitlistId)
                    return {...item, pos_updating: true, position: action.dryvwaitlist.destPos};
                else{

                    if (posForward && item.position > action.dryvwaitlist.sourcePos && item.position <= action.dryvwaitlist.destPos){
                        item.position -= 1;
                    }

                    if (!posForward && item.position < action.dryvwaitlist.sourcePos && item.position >= action.dryvwaitlist.destPos){
                        item.position += 1;
                    }

                    return item;
                }
            })
            return {
                ...state,
                w_items: _items,
                item_pos_updating: true,
            }
        case dryvwaitlistConstants.DRYVWAITLIST_ITEM_POS_UPDATE_SUCCESS:
            _items = state.w_items.map(
                item => {
                    if (item.id === action.dryvwaitlist.id){
                        return action.dryvwaitlist
                    }
                    return item;
                }
            )
            return{
                ...state,
                w_items:_items,
                item_pos_updating: false,
            }
        case dryvwaitlistConstants.DRYVWAITLIST_ITEM_POS_UPDATE_FAILURE:
            _items = state.w_items.map(
                item => {
                    if (item.id === action.dryvwaitlist.dryvwaitlistId){
                        const {pos_updating, ...itemCopy} = item;
                        return{...itemCopy, updateError: action.error}
                    }
                    return item;
                }
            )
            return{
                ...state,
                w_items: _items,
                item_pos_updating: false,
            }
        default:
            return state;
    }

}