import {templatesConstants} from "../constants";

export function  templates(state ={}, action){
    switch (action.type) {
        case templatesConstants.TEMPLATES_GETALL_REQUEST:
            return{
                loading: true
            };
        case templatesConstants.TEMPLATES_GETALL_SUCCESS:
            return {
                loading: false,
                items: action.templates.templates
            };
        case templatesConstants.TEMPLATES_GETALL_FAILURE:
            return {
                loading: false,
                error: action.error.toString()
            };
        case templatesConstants.TEMPLATES_GETITEM_REQUEST:
            return {
                item_loading: true
            };
        case templatesConstants.TEMPLATES_GETITEM_SUCCESS:
            return {
                item_loading: false,
                item:action.template
            };
        case templatesConstants.TEMPLATES_GETITEM_FAILURE:
            return {
                item_loading: false,
                item:action.error.toString()
            }
        case templatesConstants.TEMPLATES_ADD_ITEM_REQUEST:
            return{
                ...state,
                creating: true
            }
        case templatesConstants.TEMPLATES_ADD_ITEM_SUCCESS:
            return{
                ...state,
                creating: false,
                items:[...state.items, action.template.Item]
            }
        case templatesConstants.TEMPLATES_ADD_ITEM_FAILURE:
            return {
                ...state,
                creating: false,
                err: action.error.toString()
            }
        case templatesConstants.TEMPLATES_REMOVE_ITEM_REQUEST:
            let _items = state.items.map(
                item => item.templateId === action.template.templateId ? 
                {...item, deleting: true} :
                item
            )
            return {
                ...state,
                items: _items
            }
        case templatesConstants.TEMPLATES_REMOVE_ITEM_SUCCESS:
            _items = state.items.filter(item => item.templateId !== action.template.templateId)
            return{
                ...state,
                items:_items
            }
        case templatesConstants.TEMPLATES_REMOVE_ITEM_FAILURE:
            _items = state.items.map(
                item => {
                    if (item.templateId === action.template.templateId){
                        const {deleting, ...itemCopy} = item;
                        return{...itemCopy, removeError: action.error}
                    }
                    return item;
                }
            )
            return{
                ...state,
                items: _items
            }
        case templatesConstants.TEMPLATES_UPDATE_ITEM_REQUEST:
            _items = state.items.map(
                item => item.templateId === action.template.templateId ? 
                {...item, updating: true} :
                item
            )
            return {
                ...state,
                items: _items
            }
        case templatesConstants.TEMPLATES_UPDATE_ITEM_SUCCESS:
            _items = state.items.map(
                item => {
                    if (item.templateId === action.template.templateId){
                        return action.template
                    }
                    return item;
                }
            )
            return{
                ...state,
                items:_items
            }
        case templatesConstants.TEMPLATES_UPDATE_ITEM_FAILURE:
            _items = state.items.map(
                item => {
                    if (item.templateId === action.template.templateId){
                        const {updating, ...itemCopy} = item;
                        return{...itemCopy, updateError: action.error}
                    }
                    return item;
                }
            )
            return{
                ...state,
                items: _items
            }
        default:
            return state;
    }

}