import {signup_settingsConstants} from "../constants";

export function  signup_settings(state ={}, action){
    switch (action.type) {
        case signup_settingsConstants.SIGNUP_GETALL_REQUEST:
            return{
                loading: true
            };
        case signup_settingsConstants.SIGNUP_GETALL_SUCCESS:
            return {
                all_data: action.signup_settings,
                loading: false
            };
        case signup_settingsConstants.SIGNUP_GETALL_FAILURE:
            return {
                error: action.error.toString(),
                loading: false
            };
        /*case questionsConstants.QUESTIONS_ITEMADD_REQUEST:
            return {
                ...state,
                creating: true
            }
        case questionsConstants.QUESTIONS_ITEMADD_SUCCESS:
            return{
                ...state,
                creating: false,
                list: [...state.list, action.question.item]
            }
        case questionsConstants.QUESTIONS_ITEMADD_FAILURE:
            return{
                ...state,
                creating: false,
                //err: action.error.toString()
            }
        case questionsConstants.QUESTIONS_ITEMUPDATE_REQUEST:
            let _list = state.list.map(
                item => item.id === action.question.updateData.id ? {...item, updating: true} : item
            )
            return {
                ...state,
                list: _list
            }
        case questionsConstants.QUESTIONS_ITEMUPDATE_SUCCESS:
            _list = state.list.map(
                item => {
                    if (item.id === action.question.item.id){
                        return action.question.item;
                    }

                    return item;
                }
            )
            return{
                ...state,
                list: _list
            }
        case questionsConstants.QUESTIONS_ITEMUPDATE_FAILURE:
            _list = state.list.map(
                item => {
                    if (item.id === action.question.updateData.id){
                        const {updating, ...itemCopy} = item;
                        return {...itemCopy, updateError: action.error}
                    }
                }
            )
            return{
                ...state,
                list: _list
                //err: action.error.toString()
            }
        case questionsConstants.QUESTIONS_ITEMREMOVE_REQUEST:
            _list = state.list.map(
                item => item.id === action.questionId ? {...item, deleting: true} : item
            )
            return {
                ...state,
                list: _list
            }
        case questionsConstants.QUESTIONS_ITEMREMOVE_SUCCESS:
            return{
                ...state,
                list: action.questions.questions
            }
        case questionsConstants.QUESTIONS_ITEMREMOVE_FAILURE:
            _list = state.list.map(
                item => {
                    if (item.id === action.questionId){
                        const {deleting, ...itemCopy} = item;
                        return {...itemCopy, updateError: action.error}
                    }
                }
            )
            return{
                ...state,
                list: _list
                //err: action.error.toString()
            }
        case questionsConstants.QUESTIONS_STYLE_SAVE_REQUEST:
            return {
                ...state,
                styles_saving: true
            }
        case questionsConstants.QUESTIONS_STYLE_SAVE_SUCCESS:
            return{
                ...state,
                style_items: action.styles,
                styles_saving: false
            }
        case questionsConstants.QUESTIONS_STYLE_SAVE_FAILURE:
            return{
                ...state,
                styles_saving: false
            }*/
        default:
            return state;
    }

}