import {userConstants} from "../constants";

export function  users(state ={}, action){
    switch (action.type) {
        case userConstants.USERS_GETALL_REQUEST:
            return{
                loading: true
            };
        case userConstants.USERS_GETALL_SUCCESS:
            return {
                items: action.users
            };
        case userConstants.USERS_GETALL_FAILURE:
            return {
                error: action.error.toString()
            };
        case userConstants.USER_DELETE_REQUEST:
            return {
                ...state,
                items: state.items.map(user => 
                    user.id === action.id ? {...user, deleting:true} : user
                )
            }
        case userConstants.USER_DELETE_SUCCESS:
            return {
                items: state.items.filter(user => user.id !== action.id),
                delsuccess: true
            }
        case userConstants.USER_DELETE_FAILURE:
            return {
                ...state,
                items: state.items.map(user => {
                    if (user.id === action.id){
                        const { deleting, ...usercopy } = user;
                        return { ...usercopy, deleteError: action.error };
                    }
                    return user;
                })
            }
        case userConstants.USER_EDIT_REQUEST:
            return {
                ...state,
                items: state.items.map(user => 
                    user.id === action.id ? {...user, updating:true} : user    
                )
            }
        case userConstants.USER_EDIT_SUCCESS:
            return {
                ...state,
                items: state.items.map(user => {
                    if (user.id === action.userObj.id){
                        return action.userObj;
                    }
                    return user;
                })
            }
        case userConstants.USER_EDIT_FAILURE:
            return {
                ...state,
                items: state.items.map(user => {
                    if (user.id === action.id){
                        const {updating, ...usercopy} = user;
                        return {...usercopy, updateError: action.error};
                    }
                    return user;
                })
            }
        case userConstants.USER_ADD_REQUEST:
            return {
                items: state.items, 
                updating: true
            }
        case userConstants.USER_ADD_SUCCESS:
            return {
                ...state,
                items: [
                    ...state.items, action.user
                ]
            }
        case userConstants.USER_ADD_FAILURE:
            return{
                ...state,
                items: state.items
            }
        case userConstants.ME_UPDATE_REQUEST:
            return { 
                updatingme: true,
                user: action.user
            }
        case userConstants.ME_UPDATE_SUCCESS:
            return {
                updated: true,
                user: action.user
            }
        case userConstants.ME_UPDATE_FAILURE:
            return {
                updatemefailed: true,
                user: action.error
            }
        default:
            return state;
    }
}