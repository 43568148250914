import {createBrowserHistory} from 'history';
import config from '../config';

export const history = createBrowserHistory( {basename: config.basename} );

export function isLoggedIn(){
    const loggedIn = JSON.parse(localStorage.getItem('user'));
    if (loggedIn === null || loggedIn === undefined || loggedIn === false){
        return false;
    }

    return true;
}