export const signup_settingsConstants = {
    SIGNUP_GETALL_REQUEST: "SIGNUP_GETALL_REQUEST",
    SIGNUP_GETALL_SUCCESS: "SIGNUP_GETALL_SUCCESS",
    SIGNUP_GETALL_FAILURE: "SIGNUP_GETALL_FAILURE",

    SIGNUP_QUESTION_ITEMADD_REQUEST: "SIGNUP_QUESTION_ITEMADD_REQUEST",
    SIGNUP_QUESTION_ITEMADD_SUCCESS: "SIGNUP_QUESTION_ITEMADD_SUCCESS",
    SIGNUP_QUESTION_ITEMADD_FAILURE: "SIGNUP_QUESTION_ITEMADD_FAILURE",

    SIGNUP_QUESTION_ITEMUPDATE_REQUEST: "SIGNUP_QUESTION_ITEMUPDATE_REQUEST",
    SIGNUP_QUESTION_ITEMUPDATE_SUCCESS: "SIGNUP_QUESTION_ITEMUPDATE_SUCCESS",
    SIGNUP_QUESTION_ITEMUPDATE_FAILURE: "SIGNUP_QUESTION_ITEMUPDATE_FAILURE",

    SIGNUP_QUESTION_ITEMREMOVE_REQUEST: "SIGNUP_QUESTION_ITEMREMOVE_REQUEST",
    SIGNUP_QUESTION_ITEMREMOVE_SUCCESS: "SIGNUP_QUESTION_ITEMREMOVE_SUCCESS",
    SIGNUP_QUESTION_ITEMREMOVE_FAILURE: "SIGNUP_QUESTION_ITEMREMOVE_FAILURE",

    SIGNUP_COLORS_SAVE_REQUEST: "SIGNUP_COLORS_SAVE_REQUEST",
    SIGNUP_COLORS_SAVE_SUCCESS: "SIGNUP_COLORS_SAVE_SUCCESS",
    SIGNUP_COLORS_SAVE_FAILURE: "SIGNUP_COLORS_SAVE_FAILURE"
}