export const templatesConstants = {
    TEMPLATES_GETALL_REQUEST: "TEMPLATES_GETALL_REQUEST",
    TEMPLATES_GETALL_SUCCESS: "TEMPLATES_GETALL_SUCCESS",
    TEMPLATES_GETALL_FAILURE: "TEMPLATES_GETALL_FAILURE",

    TEMPLATES_GETITEM_REQUEST: "TEMPLATES_GETITEM_REQUEST",
    TEMPLATES_GETITEM_SUCCESS: "TEMPLATES_GETITEM_SUCCESS",
    TEMPLATES_GETITEM_FAILURE: "TEMPLATES_GETITEM_FAILURE",

    TEMPLATES_ADD_ITEM_REQUEST: "TEMPLATES_ADD_ITEM_REQUEST",
    TEMPLATES_ADD_ITEM_SUCCESS: "TEMPLATES_ADD_ITEM_SUCCESS",
    TEMPLATES_ADD_ITEM_FAILURE: "TEMPLATES_ADD_ITEM_FAILURE",

    TEMPLATES_UPDATE_ITEM_REQUEST: "TEMPLATES_UPDATE_ITEM_REQUEST",
    TEMPLATES_UPDATE_ITEM_SUCCESS: "TEMPLATES_UPDATE_ITEM_SUCCESS",
    TEMPLATES_UPDATE_ITEM_FAILURE: "TEMPLATES_UPDATE_ITEM_FAILURE",

    TEMPLATES_REMOVE_ITEM_REQUEST: "TEMPLATES_REMOVE_ITEM_REQUEST",
    TEMPLATES_REMOVE_ITEM_SUCCESS: "TEMPLATES_REMOVE_ITEM_SUCCESS",
    TEMPLATES_REMOVE_ITEM_FAILURE: "TEMPLATES_REMOVE_ITEM_FAILURE"
}