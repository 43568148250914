export const visitsConstants = {
    VISITS_GETALL_REQUEST: "VISITS_GETALL_REQUEST",
    VISITS_GETALL_SUCCESS: "VISITS_GETALL_SUCCESS",
    VISITS_GETALL_FAILURE: "VISITS_GETALL_FAILURE",

    VISITS_GETENTIRE_REQUEST: "VISITS_GETENTIRE_REQUEST",
    VISITS_GETENTIRE_SUCCESS: "VISITS_GETENTIRE_SUCCESS",
    VISITS_GETENTIRE_FAILURE: "VISITS_GETENTIRE_FAILURE",

    WAITING_VISITS_GETENTIRE_REQUEST: "WAITING_VISITS_GETENTIRE_REQUEST",
    WAITING_VISITS_GETENTIRE_SUCCESS: "WAITING_VISITS_GETENTIRE_SUCCESS",
    WAITING_VISITS_GETENTIRE_FAILURE: "WAITING_VISITS_GETENTIRE_FAILURE",

    SERVING_VISITS_GETENTIRE_REQUEST: "SERVING_VISITS_GETENTIRE_REQUEST",
    SERVING_VISITS_GETENTIRE_SUCCESS: "SERVING_VISITS_GETENTIRE_SUCCESS",
    SERVING_VISITS_GETENTIRE_FAILURE: "SERVING_VISITS_GETENTIRE_FAILURE",

    COMPLETE_VISITS_GETENTIRE_REQUEST: "COMPLETE_VISITS_GETENTIRE_REQUEST",
    COMPLETE_VISITS_GETENTIRE_SUCCESS: "COMPLETE_VISITS_GETENTIRE_SUCCESS",
    COMPLETE_VISITS_GETENTIRE_FAILURE: "COMPLETE_VISITS_GETENTIRE_FAILURE",

    VISITS_GETITEM_REQUEST: "VISITS_GETITEM_REQUEST",
    VISITS_GETITEM_SUCCESS: "VISITS_GETiTEM_SUCCESS",
    VISITS_GETITEM_FAILURE: "VISITS_GETITEM_FAILURE",

    VISITS_UPDATEITEM_REQUEST: "VISITS_UPDATEITEM_REQUEST",
    VISITS_UPDATEITEM_SUCCESS: "VISITS_UPDATEITEM_SUCCESS",
    VISITS_UPDATEITEM_FAILURE: "VISITS_UPDATEITEM_FAILURE",

    VISITS_REMOVEITEM_REQUEST: "VISITS_REMOVEITEM_REQUEST",
    VISITS_REMOVEITEM_SUCCESS: "VISITS_REMOVEITEM_SUCCESS",
    VISITS_REMOVEITEM_FAILURE: "VISITS_REMOVEITEM_FAILURE",

    VISIT_ITEMSTATUS_UPDATE_REQUEST: "VISIT_ITEMSTATUS_UPDATE_REQUEST",
    VISIT_ITEMSTATUS_UPDATE_SUCCESS: "VISIT_ITEMSTATUS_UPDATE_SUCCESS",
    VISIT_ITEMSTATUS_UPDATE_FAILURE: "VISIT_ITEMSTATUS_UPDATE_FAILURE",

    VISIT_ITEM_POS_UPDATE_REQUEST: "VISIT_ITEM_POS_UPDATE_REQUEST",
    VISIT_ITEM_POS_UPDATE_SUCCESS: "VISIT_ITEM_POS_UPDATE_SUCCESS",
    VISIT_ITEM_POS_UPDATE_FAILURE: "VISIT_ITEM_POS_UPDATE_FAILURE",

    VISIT_GETENTIRE_RELOAD_REQUEST: "VISIT_GETENTIRE_RELOAD_REQUEST",
    VISIT_GETENTIRE_RELOAD_SUCCESS: "VISIT_GETENTIRE_RELOAD_SUCCESS",
    VISIT_GETENTIRE_RELOAD_FAILURE: "VISIT_GETENTIRE_RELOAD_FAILURE"
}