export const eventsConstants = {
    EVENTS_GETITEM_REQUEST: "EVENTS_GETITEM_REQUEST",
    EVENTS_GETITEM_SUCCESS: "EVENTS_GETITEM_SUCCESS",
    EVENTS_GETITEM_FAILURE: "EVENTS_GETITEM_FAILURE",

    EVENTS_ADDITEM_REQUEST: "EVENTS_ADDITEM_REQUEST",
    EVENTS_ADDITEM_SUCCESS: "EVENTS_ADDITEM_SUCCESS",
    EVENTS_ADDITEM_FAILURE: "EVENTS_ADDITEM_FAILURE",

    EVENTS_REMOVEITEM_REQUEST: "EVENTS_REMOVEITEM_REQUEST",
    EVENTS_REMOVEITEM_SUCCESS: "EVENTS_REMOVEITEM_SUCCESS",
    EVENTS_REMOVEITEM_FAILURE: "EVENTS_REMOVEITEM_FAILURE",

    EVENTS_UPDATEITEM_REQUEST: "EVENTS_UPDATEITEM_REQUEST",
    EVENTS_UPDATEITEM_SUCCESS: "EVENTS_UPDATEITEM_SUCCESS",
    EVENTS_UPDATEITEM_FAILURE: "EVENTS_UPDATEITEM_FAILURE"
}