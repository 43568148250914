export function authHeader(){
    //let userObj = JSON.parse(localStorage.getItem('user'));

    if (1){
        return { 'Accept': 'application/json' };
    }else{
        return {};
    }
}

export function authHeader1(){
    //let userObj = JSON.parse(localStorage.getItem('user'));

    if (1){
        return { 'Accept': 'application/json', 'Content-Type': 'application/json' };
    }else{
        return {};
    }
}