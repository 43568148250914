export const dryvwaitlistConstants = {
    DRYVWAITLIST_GETALL_REQUEST: "DRYVWAITLIST_GETALL_REQUEST",
    DRYVWAITLIST_GETALL_SUCCESS: "DRYVWAITLIST_GETALL_SUCCESS",
    DRYVWAITLIST_GETALL_FAILURE: "DRYVWAITLIST_GETALL_FAILURE",

    DRYVWAITLIST_GETENTIRE_REQUEST: "DRYVWAITLIST_GETENTIRE_REQUEST",
    DRYVWAITLIST_GETENTIRE_SUCCESS: "DRYVWAITLIST_GETENTIRE_SUCCESS",
    DRYVWAITLIST_GETENTIRE_FAILURE: "DRYVWAITLIST_GETENTIRE_FAILURE",

    WAITING_DRYVWAITLIST_GETENTIRE_REQUEST: "WAITING_DRYVWAITLIST_GETENTIRE_REQUEST",
    WAITING_DRYVWAITLIST_GETENTIRE_SUCCESS: "WAITING_DRYVWAITLIST_GETENTIRE_SUCCESS",
    WAITING_DRYVWAITLIST_GETENTIRE_FAILURE: "WAITING_DRYVWAITLIST_GETENTIRE_FAILURE",

    SERVING_DRYVWAITLIST_GETENTIRE_REQUEST: "SERVING_DRYVWAITLIST_GETENTIRE_REQUEST",
    SERVING_DRYVWAITLIST_GETENTIRE_SUCCESS: "SERVING_DRYVWAITLIST_GETENTIRE_SUCCESS",
    SERVING_DRYVWAITLIST_GETENTIRE_FAILURE: "SERVING_DRYVWAITLIST_GETENTIRE_FAILURE",

    COMPLETE_DRYVWAITLIST_GETENTIRE_REQUEST: "COMPLETE_DRYVWAITLIST_GETENTIRE_REQUEST",
    COMPLETE_DRYVWAITLIST_GETENTIRE_SUCCESS: "COMPLETE_DRYVWAITLIST_GETENTIRE_SUCCESS",
    COMPLETE_DRYVWAITLIST_GETENTIRE_FAILURE: "COMPLETE_DRYVWAITLIST_GETENTIRE_FAILURE",

    DRYVWAITLIST_GETITEM_REQUEST: "DRYVWAITLIST_GETITEM_REQUEST",
    DRYVWAITLIST_GETITEM_SUCCESS: "DRYVWAITLIST_GETiTEM_SUCCESS",
    DRYVWAITLIST_GETITEM_FAILURE: "DRYVWAITLIST_GETITEM_FAILURE",

    DRYVWAITLIST_UPDATEITEM_REQUEST: "DRYVWAITLIST_UPDATEITEM_REQUEST",
    DRYVWAITLIST_UPDATEITEM_SUCCESS: "DRYVWAITLIST_UPDATEITEM_SUCCESS",
    DRYVWAITLIST_UPDATEITEM_FAILURE: "DRYVWAITLIST_UPDATEITEM_FAILURE",

    DRYVWAITLIST_REMOVEITEM_REQUEST: "DRYVWAITLIST_REMOVEITEM_REQUEST",
    DRYVWAITLIST_REMOVEITEM_SUCCESS: "DRYVWAITLIST_REMOVEITEM_SUCCESS",
    DRYVWAITLIST_REMOVEITEM_FAILURE: "DRYVWAITLIST_REMOVEITEM_FAILURE",

    DRYVWAITLIST_ITEMSTATUS_UPDATE_REQUEST: "DRYVWAITLIST_ITEMSTATUS_UPDATE_REQUEST",
    DRYVWAITLIST_ITEMSTATUS_UPDATE_SUCCESS: "DRYVWAITLIST_ITEMSTATUS_UPDATE_SUCCESS",
    DRYVWAITLIST_ITEMSTATUS_UPDATE_FAILURE: "DRYVWAITLIST_ITEMSTATUS_UPDATE_FAILURE",

    DRYVWAITLIST_ITEM_POS_UPDATE_REQUEST: "DRYVWAITLIST_ITEM_POS_UPDATE_REQUEST",
    DRYVWAITLIST_ITEM_POS_UPDATE_SUCCESS: "DRYVWAITLIST_ITEM_POS_UPDATE_SUCCESS",
    DRYVWAITLIST_ITEM_POS_UPDATE_FAILURE: "DRYVWAITLIST_ITEM_POS_UPDATE_FAILURE",

    DRYVWAITLIST_GETENTIRE_RELOAD_REQUEST: "DRYVWAITLIST_GETENTIRE_RELOAD_REQUEST",
    DRYVWAITLIST_GETENTIRE_RELOAD_SUCCESS: "DRYVWAITLIST_GETENTIRE_RELOAD_SUCCESS",
    DRYVWAITLIST_GETENTIRE_RELOAD_FAILURE: "DRYVWAITLIST_GETENTIRE_RELOAD_FAILURE"
}