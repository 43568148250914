import {visitsConstants} from "../constants";

export function  visits(state ={}, action){
    switch (action.type) {
        case visitsConstants.VISITS_GETALL_REQUEST:
            return{
                loading: true
            };
        case visitsConstants.VISITS_GETALL_SUCCESS:
            return {
                items: action.visits.results,
                loading: false
            };
        case visitsConstants.VISITS_GETALL_FAILURE:
            return {
                error: action.error.toString(),
                loading: false
            };
        case visitsConstants.VISITS_GETENTIRE_REQUEST:
            return{
                loading: true
            };
        case visitsConstants.VISITS_GETENTIRE_SUCCESS:
            return {
                items: action.visits.results,
                loading: false
            };
        case visitsConstants.VISITS_GETENTIRE_FAILURE:
            return {
                error: action.error.toString(),
                loading: false
            };
        case visitsConstants.VISIT_GETENTIRE_RELOAD_REQUEST:
            return{
                ...state,
                loading: false,
                pos_reloading: true
            };
        case visitsConstants.VISIT_GETENTIRE_RELOAD_SUCCESS:
            return {
                items: action.visits.results,
                pos_reloading: false,
                loading: false
            };
        case visitsConstants.VISIT_GETENTIRE_RELOAD_FAILURE:
            return {
                ...state,
                error: action.error.toString(),
                pos_reloading: false,
                loading: false
            };
        case visitsConstants.VISITS_GETITEM_REQUEST:
            return {
                loading: true
            };
        case visitsConstants.VISITS_GETITEM_SUCCESS:
            return {
                loading: false,
                item:action.visit
            };
        case visitsConstants.VISITS_GETITEM_FAILURE:
            return {
                loading: false,
                item:action.error.toString()
            }
        case visitsConstants.VISITS_UPDATEITEM_REQUEST:
            return {
                loading: true
            };
        case visitsConstants.VISITS_UPDATEITEM_SUCCESS:
            return {
                loading: false,
                item:action.visit
            };
        case visitsConstants.VISITS_UPDATEITEM_FAILURE:
            return {
                loading: false,
                item:action.error.toString()
            }
        case visitsConstants.VISITS_REMOVEITEM_REQUEST:

            return {
                ...state,
                loading: true
            }

        case visitsConstants.VISITS_REMOVEITEM_SUCCESS:

            return{
                loading: false
            }
        case visitsConstants.VISITS_REMOVEITEM_FAILURE:
            return{
                ...state,
                loading: false
            }
        case visitsConstants.VISIT_ITEMSTATUS_UPDATE_REQUEST:
            let _items = state.items.map(
                item => item.id === action.visit.visitId ? 
                {...item, item_updating: true} :
                item
            )
            return {
                ...state,
                items: _items
            }
        case visitsConstants.VISIT_ITEMSTATUS_UPDATE_SUCCESS:
            _items = state.items.map(
                item => {
                    if (item.id === action.visit.id){
                        return action.visit
                    }
                    return item;
                }
            )
            return{
                ...state,
                items:_items
            }
        case visitsConstants.VISIT_ITEMSTATUS_UPDATE_FAILURE:
            _items = state.items.map(
                item => {
                    if (item.id === action.visit.visitId){
                        const {item_updating, ...itemCopy} = item;
                        return{...itemCopy, updateError: action.error}
                    }
                    return item;
                }
            )
            return{
                ...state,
                items: _items
            }
        case visitsConstants.VISIT_ITEM_POS_UPDATE_REQUEST:

            const posForward = action.visit.posForward;

            _items = state.items.map(
                item => {
                if (item.id === action.visit.visitId)
                    return {...item, pos_updating: true, position: action.visit.destPos};
                else{

                    if (posForward && item.position > action.visit.sourcePos && item.position <= action.visit.destPos){
                        item.position -= 1;
                    }

                    if (!posForward && item.position < action.visit.sourcePos && item.position >= action.visit.destPos){
                        item.position += 1;
                    }

                    return item;
                }
            })
            return {
                ...state,
                items: _items
            }
        case visitsConstants.VISIT_ITEM_POS_UPDATE_SUCCESS:
            _items = state.items.map(
                item => {
                    if (item.id === action.visit.id){
                        return action.visit
                    }
                    return item;
                }
            )
            return{
                ...state,
                items:_items
            }
        case visitsConstants.VISIT_ITEM_POS_UPDATE_FAILURE:
            _items = state.items.map(
                item => {
                    if (item.id === action.visit.visitId){
                        const {pos_updating, ...itemCopy} = item;
                        return{...itemCopy, updateError: action.error}
                    }
                    return item;
                }
            )
            return{
                ...state,
                items: _items
            }
        case visitsConstants.WAITING_VISITS_GETENTIRE_REQUEST:
            return{
                ...state,
                w_loading: true
            };
        case visitsConstants.WAITING_VISITS_GETENTIRE_SUCCESS:

            /*let _w_items1 = state.w_items ? state.w_items : [];
            for (let i = 0; i < action.visits.results.length; i ++){
                let exist = false;
                _w_items1 = _w_items1.map((item, index) => {
                    if (item.id === action.visits.results[i].id){
                        exist = true;
                        return action.visits.results[i];
                    }

                    return item;
                })

                if (!exist) _w_items1.push(action.visits.results[i]);
            }*/

            return {
                ...state,
                w_items: action.visits.results, //_w_items1,
                w_loading: false
            };
        case visitsConstants.WAITING_VISITS_GETENTIRE_FAILURE:
            return {
                ...state,
                w_error: action.error.toString(),
                w_loading: false
            };

        case visitsConstants.SERVING_VISITS_GETENTIRE_REQUEST:
            return{
                ...state,
                s_loading: true
            };
        case visitsConstants.SERVING_VISITS_GETENTIRE_SUCCESS:
            return {
                ...state,
                s_items: action.visits.results,
                s_loading: false
            };
        case visitsConstants.SERVING_VISITS_GETENTIRE_FAILURE:
            return {
                ...state,
                s_error: action.error.toString(),
                s_loading: false
            };

        case visitsConstants.COMPLETE_VISITS_GETENTIRE_REQUEST:
            return{
                ...state,
                c_loading: true
            };
        case visitsConstants.COMPLETE_VISITS_GETENTIRE_SUCCESS:

            // let _c_items1 = state.c_items ? state.c_items : [];
            // if (action.visits && action.visits.results){
            //     for (let i = 0; i < action.visits.results.length; i ++){
            //         if (_c_items1.filter(item => item.id === action.visits.results[i].id).length === 0){
            //             _c_items1.push(action.visits.results[i]);
            //         }
            //     }
            // }

            // let _c_startAfter = state.c_startAfter ? state.c_startAfter : '';
            // if (action.visits && action.visits.endAt) _c_startAfter = action.visits.endAt;

            return {
                ...state,
                c_items:  action.visits,// _c_items1,
                //c_startAfter: _c_startAfter,
                c_loading: false
            };
        case visitsConstants.COMPLETE_VISITS_GETENTIRE_FAILURE:
            return {
                ...state,
                c_error: action.error.toString(),
                c_loading: false
            };    
        default:
            return state;
    }

}