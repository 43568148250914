import {combineReducers} from 'redux';

import { auth } from './auth.reducer';
import { alert } from './alert.reducer';
import { reducer } from '../store/reducer';
import { users } from './users.reducer';
import { locations } from './locations.reducer'
import { visits } from './visits.reducer'
import { signup_settings } from './signup_settings.reducer'
import { events } from './events.reducer'
import { templates } from './templates.reducer';
import { dryvwaitlist } from './dryvwaitlist.reducer'
const rootReducer = combineReducers({
    reducer,
    auth,
    alert,
    users,
    locations,
    visits,
    signup_settings,
    events,
    templates,
    dryvwaitlist
});

export default rootReducer;